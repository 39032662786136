import { useEffect, useState } from "react";
import { MailIcon, PencilIcon, EyeIcon, PencilAltIcon } from "@heroicons/react/outline";
import ListGroupItems from '../../../components/ListGroupItems'
import StackedList from "../../../components/StackedList";
import { useToastAction } from "../../../hooks/useToastAction";
import { getLastDialogValues } from "../../../api/dialogValues";
import { getCustomerInfo } from "../../../api/customer";
import { config } from '../../../utils/config';
import { useLicense } from "../../../hooks/useLicense";

export default function Home() {
    const dummyLoadData = [
        {}, {}, {}, {}, {}
    ];

    const homeAction = useToastAction();
    const [homeData, setHomeData] = useState([{}]);
    const [isLoaded, setIsLoaded] = useState(false);
    const { hasDigitalSigningLicense, hasSmartFormsLicense } = useLicense();
    
    useEffect(() => {
        load();
    }, []);
    
    const items = [
        {
            name: "Smartforms",
            description: "Start a digital communication with one or more people involved to finalize a digital document.",
            href: "/send",
            iconColor: "bg-pink-500",
            icon: MailIcon,
            disabled: !hasSmartFormsLicense(),
        },
        {
            name: "Signature",
            description: "Get a legal acceptance from one or more people signing a PDF file in a certain order and with variouse people involved.",
            href: config.digitalSigningUiBaseUrl,
            isExternal: true,
            iconColor: "bg-purple-500",
            icon: PencilIcon,
            disabled: !hasDigitalSigningLicense(),
        },
        {
            name: "View",
            description: "Monitor and follow up on your communication with other people. This feature will help you to track things that has stopped and either cancel or send a reminder.",
            href: "/view/smartforms",
            iconColor: "bg-yellow-500",
            icon: EyeIcon,
            disabled: false
        },
        {
            name: "Edit",
            description: "Pick a document from a template library to edit and distribute as PDF file.",
            href: "/document",
            iconColor: "bg-pink-500",
            icon: PencilAltIcon,
            disabled: true
        },
    ];


      const load = () => {
        homeAction.execute( async () => {
          const { customerId } = await getCustomerInfo();
          const lastDialogs = await getLastDialogValues(customerId);
          setHomeData(lastDialogs);
          setIsLoaded(true);
        }, "Failed to load home");
      }

    return (
        <>
            <div className="w-full h-full">
                <section className="w-full h-full">
                    <h1 className="text-3xl font-bold">
                        Dialogs
                    </h1>
                    <h3 className="mt-4 text-gray-600">
                    The place were you can establish new communication or follow up on existing.  <br />
                    You will be able to issue digital forms, standard documents, 
                    signing of documents based on the access given to you as a user.
                    </h3>

                    <ListGroupItems items={items}/> 
                    
                    {
                        isLoaded ?
                        <StackedList title="Your latest activity" subtitle="This is the last forms that has been sent" items={homeData}/> 
                        :
                        <StackedList title="Your latest activity" subtitle="This is the last forms that has been sent" items={dummyLoadData}/> 
                    }
                </section>
            </div>
        </>
    );
}